<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <!-- <CRow>
                <CCol col="6" class="text-left"><h4>Merchant Default Image</h4></CCol>
                <CCol col="6" class="text-right"></CCol> 
            </CRow>   -->
            <h4>Merchant Default Image</h4> 
            <sub-title>Required dimension (width*height) :  60 x 60</sub-title>    
          </CCardHeader>
          <CCardBody>
              <CForm>

                <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
                </CRow> 
                <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Upload Image</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <input type="file" :v-model="srcFile" ref="myFile" @change="onFileChangedImg">
                      <b-button-group>    
                      <CButton @click="onUplonUploadImg" :disabled="!isDisabledbtnImg" color="success">Upload</CButton>
                      <CButton  v-on:click="actionShowImg()" color="warning">Show</CButton>
                      <CButton  v-on:click="actionDownloadImg()" color="primary">Download</CButton>                      
                      </b-button-group>   
                  </b-col>                  
                </b-row><br/>
              
                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Preview Image </label>
                     <!-- {{image.width}} * {{image.height}} -->
                  </b-col>   
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps" :src="'data:image/png;base64,'+ imgMerchant " alt=""></b-img>
                  </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">
                  </b-col>   
                  <b-col sm="9"> 
                      <CButton  v-on:click="actionRefresh()" color="primary">Refresh Preview</CButton>
                  </b-col>                  
                </b-row><br/>
                  
              </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>  
</div>
</template> 

<script>
 import AuthService from '@/api/AuthService.js'; 
 import PromoService from '@/api/PromoService.js';
 import MerchantService from '@/api/MerchantService.js';
 
  export default { 
    name: 'merchant_default_img',
    components: { 
    },     
    data() {
      return { 
          image:{
            size:0,
            height:0,
            width:0
          },
          srcFile:'',
          imgType : '',
          imgMerchant:'',         
          defaultImageKey:'',
          selectedFile: null, 
          isDisabledbtnImg:false,          
          imgProps: {  width: 200, height: 200  },  
          spinner:false,
          seen:false,
          msg :'',
          color:'',
          count:1
        };      
    },
    created () { 
        this.getDefaultImageList();
    },
    methods: {

      actionRefresh(){
          this.getDefaultImageList(); 
          this.actionShowImg();
      },

      onImageLoadFailure (event) {
            event.target.src = 'img/default.png'     
      },

      actionShowImg(){   
         this.getDefaultImageList();   
         var key  = this.defaultImageKey;  
         var temp = key.split("."); var type  = temp[1];        
         this.downloadDefaultImg(key,type,"true");
      },

      actionDownloadImg(){
        var key  = this.defaultImageKey;  
        var temp = key.split("."); var type  = temp[1];        
        this.downloadDefaultImg(key,type);          
      },    

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
         this.isDisabledbtnImg = true;
      },

      async onUplonUploadImg() { 

          this.spinner = true;
          this.imageLoaded = false;
          let file = this.$refs.myFile.files[0];

          if(!file || file.type.indexOf('image/') !== 0) return;
      
          this.image.size = file.size;          
          let reader = new FileReader();

           
            reader.readAsDataURL(file);
            reader.onload = evt => {
              let img = new Image();
              img.onload = () => {
                this.image.width  = img.width;
                this.image.height = img.height;
                this.imageLoaded  = true; 
              }
              img.src = evt.target.result;
            }
            reader.onerror = evt => { console.error(evt); } 
            setTimeout( () =>  this.actionSubmit() , 5000);          

      },     

      async actionSubmit(){

          if(this.image.width === 60 && this.image.height === 60) {  
          //if(this.image.width !== 0 && this.image.height !== 0) {  
            const formData = new FormData()
            formData.append('resource', this.selectedFile, this.selectedFile.name);         
            try {                        
                const resp = await MerchantService.uploadDefaultImageMerchant(formData);   //console.log(resp);
                if(resp.statusCode==="S0000"){ 
                    let val    = { "default_image_key" : resp.message };
                    var value  = JSON.stringify(val);
                    let params = { key : "merchant_config", value : value }; 
                    this.updateSystemConfig(params);          
                } else {
                    this.msg   =  !resp.data.message ? "Upload Default Image Promo Failed!" : resp.data.message; 
                    this.color = 'danger'; this.seen  = true;              
                } 
                setTimeout( () =>  this.seen=false , 5000); 
            } catch (error) { console.log(error); }
          } else { 
              this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height; 
              this.color = 'warning'; this.seen  = true;  this.spinner = false;
              setTimeout( () =>  this.seen=false , 5000);
          };
      }, 

      updateSystemConfig: function(params) {
        AuthService.updateSystemConfig(params).then(resp => { //console.log(resp);

             if(resp.message=='Success'){
                this.getDefaultImageList(); this.isDisabledbtnImg = false; //this.actionShowImg();
                this.msg   = 'Upload Default Image Merchant Success'; 
                this.color = 'success';  this.seen  = true;  
              } else {
                this.msg   = !resp.data.message ? "Upload Default Image Merchant Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }

              this.spinner = false;

        }, error => {
          this.loading = false;
        });
      },   

      downloadDefaultImg: function(key,type,show) { 
          MerchantService.downloadDefaultImg(key,type).then(resp => {  //console.log(resp);

              if(show==="true"){ 
                  this.imgMerchant = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a"); 
                  a.href = "data:image/png; base64," + ImageBase64; 
                  a.download = key; a.click();
              }                  
           
          }, error => {
            console.log('error');    
          });
      }, 
      
      getDefaultImageList: function() { 
          PromoService.getDefaultImageList().then(resp => {   //console.log(resp);

              var obj = JSON.parse(resp.merchantDefaulImage); 
              this.defaultImageKey = obj.default_image_key;             
           
          }, error => {
            console.log('error');    
          });
      }, 
    }
  }
</script>